import React, { useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import moment from "moment";
import CustomDialog from "components/common/CustomDialog";
import SmtpTable from "./SmtpTable";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const initialSmtp = {
  server: "",
  username: "",
  password: "",
  port: "",
}

const General = () => {
  const { user, reloadUser } = useAuth();
  const passwordRef = useRef();
  const [smtp, setSmtp] = useState(initialSmtp);
  const [showPassword, setShowPassword] = useState(false);
  const [smtpValidity, setSmtpValidity] = useState({
    valid: user?.smtpConfig?.valid,
    lastValidTime: user?.smtpConfig?.lastValidTime,
  });
  const [isEmailSending, setIsEmailSending] = useState(false);



  const sendTestEmail = async () => {
    setIsEmailSending(true);
    try {
      const response = await customAxios.post("profile/sendTestEmail", {
        ...smtp,
        from: smtp?.username,
        to: "test@itscredible.com",
      });



      if (response?.status === 201) {
        await customAxios.post("profile/setupSMTP", {...smtp,valid: true,lastValidTime: Date.now()});

        setSmtpValidity({
          valid: true,
          lastValidTime: Date.now(),
        });
      } else {
        setSmtpValidity({
          valid: false,
          lastValidTime: Date.now(),
          error: response?.data?.error?.message || "Something went wrong",
        });
      }
      await reloadUser();
    } catch (error) {
      console.log("error from test", error);
    } finally {
      setIsEmailSending(false);
    }
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);


  return (
    <Stack>
      <CustomDialog
        AnchorComponent={(anchorProps) => (
          <Button startIcon={<AddIcon />} variant="contained" color="primary" {...anchorProps}>
            Add
          </Button>
        )}
        title="Add SMTP"
        Content={(dialogProps) => (
          <Stack {...dialogProps} spacing={3} py={3}>
            <TextField
              label="Mail Server"
              variant="outlined"
              size="small"
              fullWidth
              helperText="smtp.gmail.com, smtp-mail.outlook.com, etc"
              defaultValue={smtp?.server}
              onBlur={(e) =>
                setSmtp((prevState) => ({ ...prevState, server: e.target.value }))
              }
            />
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
              helperText="UserName provided by SMTP provider"
              defaultValue={smtp?.username}
              onBlur={(e) =>
                setSmtp((prevState) => ({ ...prevState, username: e.target.value }))
              }
            />
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <TextField
                inputRef={passwordRef}
                type={showPassword ? "text" : "password"}
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                      aria-label={
                        showPassword ? 'hide the password' : 'display the password'
                      }
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                  ),
                }}
              helperText="Password provided by SMTP provider"
              defaultValue={smtp?.password}
              onBlur={(e) =>{
                setSmtp((prevState) => ({ ...prevState, password: e.target.value }))
              }}
              />

            </Stack>
            <TextField
              label="Port"
              variant="outlined"
              size="small"
              fullWidth
              helperText="587, 25, 465, 2525, or etc"
              defaultValue={smtp?.port}
              onBlur={(e) =>
                setSmtp((prevState) => ({ ...prevState, port: e.target.value }))
              }
            />
            <Paper
              variant="outlined"
              sx={{
                backgroundColor: "primary.surface",
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={sendTestEmail}
                disabled={isEmailSending}
                size="large"
                disableElevation
                sx={{ px: 2, flexShrink: 0 }}
              >
                Validate
              </Button>
              {isEmailSending ? (
                <Box display="flex" alignItems="center" mx={2}>
                  {/* <CircularProgress size={20} /> */}
                  <Box ml={1}>Validating...</Box>
                </Box>
              ) : smtpValidity?.valid === true ? (
                <Stack direction="column" mx={2} spacing={0.5}>
                  <Typography variant="body1" color="success.main" noWrap>
                    SMTP setup successfully done.
                  </Typography>
                  <Typography variant="body2" color="text.primary" noWrap>
                    {moment(smtpValidity?.lastValidTime).format("lll")}
                  </Typography>
                </Stack>
              ) : smtpValidity?.valid === false ? (
                <Stack direction="column" mx={2} spacing={0.5}>
                  <Typography variant="body1" color="error.main" noWrap>
                    SMTP setup failed.
                  </Typography>
                  <Typography variant="body2" color="text.primary" noWrap>
                    Click the Validate button to retry
                  </Typography>
                  <Typography variant="body2" color="text.primary" noWrap>
                    {moment(smtpValidity?.lastValidTime).format("lll")}
                  </Typography>
                </Stack>
              ) : null}
              {!isEmailSending && smtpValidity?.error ? (
                <Alert icon={false} severity="error">
                  {smtpValidity?.error}
                </Alert>
              ) : null}
            </Paper>
          </Stack>
        )}
      />
      <SmtpTable smtpConfig={user?.smtpConfig} />
    </Stack>
  );
};

export default General;
