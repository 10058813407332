import React from "react";
import { useAuth } from "hooks/useAuth";
import EmailTemplates from "./EmailTemplates";
import TextFieldLayout from "components/common/TextfieldLayout";
import Editor from "components/common/Editor";
import {
  Stack,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Chip,
  Alert,
  Typography,
} from "@mui/material";
import CustomDialog from "components/common/CustomDialog";
import Smtp from "components/settings/Smtp";

export default function SendEmailNotificationForm(props) {
  const { formData, setFormData, to } = props;
  const { user } = useAuth();

  const smtpConfig = user?.smtpConfig?.filter(smtp => smtp.valid);

  return (
    <Stack spacing={3}>
      {smtpConfig?.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">From</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData?.from?.address}
            label="From"
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                emailSettings: {
                  ...prevState?.emailSettings,
                  from: {
                    name: user.displayName,
                    address: e.target.value,
                  },
                },
              }))
            }
          >
            <MenuItem value={process.env.REACT_APP_NOTIFICATION_EMAIL}>
              {process.env.REACT_APP_NOTIFICATION_EMAIL}
            </MenuItem>
            {user?.smtpConfig?.map((smtp) => smtp.valid && (
              <MenuItem key={smtp._id} value={smtp.username}>
                {smtp.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Stack spacing={2}>
          <Alert severity="warning">
            We recommend you to setup your SMTP.&nbsp;&nbsp;
            <CustomDialog
              AnchorComponent={({ onClick }) => (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={onClick}
                >
                  Setup Now
                </span>
              )}
              title="Setup SMTP"
              Content={(dialogProps) => <Smtp {...dialogProps} />}
            />
          </Alert>
          <TextField
            size="small"
            label="From"
            value={formData?.from?.address}
            disabled={true}
            fullWidth
          />
        </Stack>
      )}

      <TextFieldLayout label="To">
        <Stack
          direction="row"
          flexWrap="wrap"
          pt={1.1}
          pb={0.3}
          pl={1}
          pr={0.5}
          sx={{ overflow: "auto", maxHeight: "150px" }}
        >
          {to?.map((email) => (
            <Box key={email} mr={0.5} mb={0.5}>
              <Chip label={email} size="small" />
            </Box>
          ))}
        </Stack>
      </TextFieldLayout>

      {formData?.from?.address === process.env.REACT_APP_NOTIFICATION_EMAIL && (
        <TextField
          size="small"
          label="Reply-to"
          fullWidth
          value={formData?.replyTo}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              emailSettings: {
                ...prevState?.emailSettings,

                replyTo: e.target.value,
              },
            }))
          }
        />
      )}

      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="caption">Email Templates</Typography>
          <EmailTemplates
            onApply={({ subject, body }) =>
              setFormData((prevState) => ({
                ...prevState,
                emailSettings: {
                  ...prevState?.emailSettings,
                  subject,
                  body,
                },
              }))
            }
          />
        </Stack>
        <TextField
          size="small"
          label="Subject"
          fullWidth
          required
          value={formData?.subject}
          InputLabelProps={{ shrink: Boolean(formData?.subject) }}
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              emailSettings: {
                ...prevState?.emailSettings,
                subject: e.target.value,
              },
            }))
          }
        />

        <Editor
          label="Email Body"
          value={formData?.body}
          onChange={(newValue) =>
            setFormData((prevState) => ({
              ...prevState,
              emailSettings: {
                ...prevState?.emailSettings,
                body: newValue,
              },
            }))
          }
        />
      </Stack>
    </Stack>
  );
}
