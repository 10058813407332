const {
    TableContainer,
    Table, TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    IconButton,
    Stack,
    TextField,
    Button,
    Paper,
    Box,
    Alert
} = require("@mui/material");
const { default: CustomDialog } = require("components/common/CustomDialog");
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { customAxios } from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";
import MySwal from "utils/Swal";



const SmtpTableRow = ({ smtp }) => {

    const [isPasswordEditable, setIsPasswordEditable] = useState(false);
    const [thisSmtp, setThisSmtp] = useState(smtp);
    const [password, setPassword] = useState(smtp?.password);
    const [isEmailSending, setIsEmailSending] = useState(false);
    const [smtpValidity, setSmtpValidity] = useState({
        valid: smtp?.valid,
        lastValidTime: smtp?.lastValidTime,
    });

    const { reloadUser } = useAuth();


    const passwordRef = useRef();

    const handleDeleteSmtp = async () => {

        MySwal.fire({
            title: "Are you sure?",
            text: "Do you want to delete the selected smtp?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                MySwal.showLoader("Processing...");
                const response = await customAxios.delete("profile/deleteSMTP", { data: { smtpId: thisSmtp?._id } });
                await reloadUser();
                if (response?.status === 200 || response?.status === 201) {
                    MySwal.fire({
                        title: "SMTP Deleted",
                        text: "The selected smtp has been deleted.",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    const makePasswordEditable = () => {
        setPassword("");
        setIsPasswordEditable(true);
    }

    const makePasswordNonEditable = () => {
        setPassword(smtp?.password);
        setIsPasswordEditable(false);
    }

    const sendTestEmail = async () => {
        setIsEmailSending(true);
        try {
            const response = await customAxios.post("profile/sendTestEmail", {
                ...thisSmtp,
                smtpId: thisSmtp?._id,
                from: thisSmtp?.username,
                to: "test@itscredible.com",
            });



            if (response?.status === 201) {
                await customAxios.post("profile/setupSMTP", { ...thisSmtp, smtpId: thisSmtp?._id, valid: true, lastValidTime: Date.now() });

                setSmtpValidity({
                    valid: true,
                    lastValidTime: Date.now(),
                });
            } else {
                await customAxios.post("profile/setupSMTP", { ...thisSmtp, smtpId: thisSmtp?._id, valid: false, lastValidTime: Date.now() });

                setSmtpValidity({
                    valid: false,
                    lastValidTime: Date.now(),
                    error: response?.data?.error?.message || "Something went wrong",
                });
            }
            await reloadUser();
        } catch (error) {
            console.log("error from test", error);
        } finally {
            setIsEmailSending(false);
        }
    };

    useEffect(() => {
        if (isPasswordEditable) {
            passwordRef?.current?.focus();
        }
    }, [isPasswordEditable]);


    return (
        <TableRow>
            <TableCell>
                <Typography variant="h5">{thisSmtp?.server}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h5">{thisSmtp?.username}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="h5">{thisSmtp?.port}</Typography>
            </TableCell>
            <TableCell>
                {
                    thisSmtp?.valid ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography color="success.main" variant="h5">{moment(thisSmtp?.lastValidTime).format("lll")}</Typography>
                            <IconButton size="small">
                                <CheckIcon color="success" fontSize="small" />
                            </IconButton>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography color="error.main" variant="h5">{moment(thisSmtp?.lastValidTime).format("lll")}</Typography>
                            <IconButton size="small">
                                <ErrorOutline color="error" fontSize="small" />
                            </IconButton>
                        </Stack>
                    )
                }
            </TableCell>

            <TableCell align="right">
                <IconButton onClick={() => handleDeleteSmtp(thisSmtp?._id)} size="small">
                    <DeleteOutline color="error" fontSize="small" />
                </IconButton>

                <CustomDialog
                    AnchorComponent={(anchorProps) => (
                        <IconButton {...anchorProps} size="small">
                            <EditOutlined color="primary" fontSize="small" />
                        </IconButton>
                    )}
                    title="Edit SMTP"
                    Content={(dialogProps) => (
                        <Stack {...dialogProps} spacing={3} py={3}>
                            <TextField
                                label="Mail Server"
                                variant="outlined"
                                size="small"
                                fullWidth
                                helperText="smtp.gmail.com, smtp-mail.outlook.com, etc"
                                defaultValue={thisSmtp?.server}
                                onBlur={(e) =>
                                    setThisSmtp((prevState) => ({ ...prevState, server: e.target.value }))
                                }
                            />
                            <TextField
                                label="Username"
                                variant="outlined"
                                size="small"
                                fullWidth
                                helperText="UserName provided by SMTP provider"
                                defaultValue={thisSmtp?.username}
                                onBlur={(e) =>
                                    setThisSmtp((prevState) => ({ ...prevState, username: e.target.value }))
                                }
                            />
                            <Stack direction="row" alignItems="flex-start" spacing={1}>
                                <TextField
                                    inputRef={passwordRef}
                                    type={isPasswordEditable ? "text" : "password"}
                                    disabled={!isPasswordEditable}
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    autoFocus
                                    fullWidth
                                    helperText="Password provided by SMTP provider"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                />
                                {isPasswordEditable ? (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                makePasswordNonEditable();
                                                setThisSmtp((prevState) => ({ ...prevState, password: password }));
                                            }}
                                            size="small">
                                            <CheckIcon color="primary" />
                                        </IconButton>
                                        <IconButton
                                            onClick={makePasswordNonEditable}
                                            size="small">
                                            <ClearIcon color="error" />
                                        </IconButton>
                                    </>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        disableElevation
                                        startIcon={<EditIcon />}
                                        onClick={makePasswordEditable}
                                    >
                                        Edit
                                    </Button>
                                )}
                            </Stack>
                            <TextField
                                label="Port"
                                variant="outlined"
                                size="small"
                                fullWidth
                                helperText="587, 25, 465, 2525, or etc"
                                defaultValue={thisSmtp?.port}
                                onBlur={(e) =>
                                    setThisSmtp((prevState) => ({ ...prevState, port: e.target.value }))
                                }
                            />
                            <Paper
                                variant="outlined"
                                sx={{
                                    backgroundColor: "primary.surface",
                                    display: "flex",
                                    alignItems: "center",
                                    p: 2,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={sendTestEmail}
                                    disabled={isEmailSending}
                                    size="large"
                                    disableElevation
                                    sx={{ px: 2, flexShrink: 0 }}
                                >
                                    Validate
                                </Button>
                                {isEmailSending ? (
                                    <Box display="flex" alignItems="center" mx={2}>
                                        {/* <CircularProgress size={20} /> */}
                                        <Box ml={1}>Validating...</Box>
                                    </Box>
                                ) : smtpValidity?.valid === true ? (
                                    <Stack direction="column" mx={2} spacing={0.5}>
                                        <Typography variant="body1" color="success.main" noWrap>
                                            SMTP setup successfully done.
                                        </Typography>
                                        <Typography variant="body2" color="text.primary" noWrap>
                                            {moment(smtpValidity?.lastValidTime).format("lll")}
                                        </Typography>
                                    </Stack>
                                ) : smtpValidity?.valid === false ? (
                                    <Stack direction="column" mx={2} spacing={0.5}>
                                        <Typography variant="body1" color="error.main" noWrap>
                                            SMTP setup failed.
                                        </Typography>
                                        <Typography variant="body2" color="text.primary" noWrap>
                                            Click the Validate button to retry
                                        </Typography>
                                        <Typography variant="body2" color="text.primary" noWrap>
                                            {moment(smtpValidity?.lastValidTime).format("lll")}
                                        </Typography>
                                    </Stack>
                                ) : null}
                                {!isEmailSending && smtpValidity?.error ? (
                                    <Alert icon={false} severity="error">
                                        {smtpValidity?.error}
                                    </Alert>
                                ) : null}
                            </Paper>
                        </Stack>
                    )}
                />
            </TableCell>
        </TableRow>
    )
}




const SmtpTable = ({ smtpConfig }) => {
    return (
        <TableContainer component={Paper} variant="outlined">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width="15%">Mail Server</TableCell>
                        <TableCell width="25%">Username</TableCell>
                        <TableCell width="10%">Port</TableCell>
                        <TableCell width="30%">Status/Last Validated</TableCell>
                        <TableCell width="20%" align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!smtpConfig?.length ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                No Data Found.
                            </TableCell>
                        </TableRow>
                    ) :
                        (
                            smtpConfig.map(smtp => (
                                <SmtpTableRow key={smtp?._id} smtp={smtp} />
                            )))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export default SmtpTable;